import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	sidePanelHeader: {
		id: 'issue-create-side-panel.header',
		defaultMessage: 'Create Jira issue',
		description: 'Title of the side panel that allows users to create a Jira issue',
	},
	sidePanelFooterSubmit: {
		id: 'issue-create-side-panel.footer.submit',
		defaultMessage: 'Submit',
		description: 'Submit button text for the side panel that allows users to create a Jira issue',
	},
	sidePanelFooterCancel: {
		id: 'issue-create-side-panel.footer.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel button text for the side panel that allows users to close side panel',
	},
	sidePanelIssueCreateInsertionFailed: {
		id: 'issue-create-side-panel.insertion-failed',
		defaultMessage:
			'We were unable to insert a link into the page for <link>{issueKey} {issueSummary}</link>',
		description: 'Error message for when issue insertion to the page fails',
	},
	sidePanelFooterDisclaimerText: {
		id: 'issue-create-side-panel.footer.disclaimer-text',
		defaultMessage: 'Content quality may vary',
		description: 'Info Text in the footer.',
	},
	sidePanelFooterAIText: {
		id: 'issue-create-side-panel.footer.ai-text',
		defaultMessage: 'Powered by AI',
		description: 'Text that describes that this feature is powered by Atlassian Intelligence',
	},
	sidePanelAiLoading: {
		id: 'issue-create-side-panel.body.ai-loading',
		defaultMessage: 'Generating issue',
		description: 'Text that shows the AI issue create component is loading',
	},
	aiErrorDescription: {
		id: 'issue-create-side-panel.ai-error-description',
		defaultMessage:
			'Atlassian Intelligence can‘t provide a response right now. Try again or create your issue manually.',
		description: 'Error message for when Atlassian Intelligence results fail to be fetched',
	},
	aiErrorTryAgainButton: {
		id: 'issue-create-side-panel.ai-error.button.try-again',
		defaultMessage: 'Try again',
		description: 'Text displayed on try again button',
	},
	aiErrorCreateManuallyButton: {
		id: 'issue-create-side-panel.ai-error.button.create-manually',
		defaultMessage: 'Create Manually',
		description: 'Text displayed on create manually button',
	},
	sidePanelIssueCreateSuccessFlagTitle: {
		id: 'issue-create-side-panel.sucess-flag-title',
		defaultMessage: 'You’ve created <link>{issueKey} {issueSummary}</link> issue',
		description: 'Title of the success flag that is shown when an issue is created successfully',
	},
	sidePanelBetaText: {
		id: 'issue-create-side-panel.beta-text',
		defaultMessage: 'Beta',
		description: 'Beta lozenge displayed in the header of the side panel',
	},
});
