import React, { type FC } from 'react';

import { Box } from '@atlaskit/primitives';
import { FlagsProvider } from '@atlaskit/flag';

import {
	SmartsFeedbackComponent,
	FeedbackCollectorPropertiesProvider,
} from '@confluence/quick-summary';
import { useIssueCreateSidePanelContext } from './IssueCreateSidePanelContextProvider';
import { fg } from '@confluence/feature-gating';

const FEEDBACK_ENTRYPOINT_ID = '5dd040e8-af22-4ef1-803e-136f659a1092';
const SOURCE = 'confluenceHighlightActionsMenu';

type IssueCreateAiFeedbackProps = {
	contentId: string;
};

export const IssueCreateAiFeedback: FC<IssueCreateAiFeedbackProps> = ({ contentId }) => {
	const [{ aiState }] = useIssueCreateSidePanelContext();
	const shouldShowFeedbackCollector = fg('hide_feedback_collector_until_results_shown')
		? aiState !== 'loading'
		: true;
	return shouldShowFeedbackCollector ? (
		<FlagsProvider>
			<FeedbackCollectorPropertiesProvider entrypointId={FEEDBACK_ENTRYPOINT_ID}>
				<Box padding="space.100">
					<SmartsFeedbackComponent analyticsProperties={{ source: SOURCE }} contentId={contentId} />
				</Box>
			</FeedbackCollectorPropertiesProvider>
		</FlagsProvider>
	) : null;
};
