import { useEffect, useState } from 'react';

import { di } from 'react-magnetic-di';

import { getAvailableJiraSites } from '../../common/utils';

export const useGetFirstAvailableSiteDomain = (preferredCloudId: string | undefined) => {
	di(getAvailableJiraSites);
	const [domain, setDomain] = useState<string | undefined>(undefined);
	const [availableSites, setAvailableSites] = useState<any>([]);

	useEffect(() => {
		(async () => {
			const { sites } = await getAvailableJiraSites();
			setAvailableSites(sites ?? []);
			setDomain((sites.find((site) => site.cloudId === preferredCloudId) ?? sites[0])?.url ?? '');
		})();
	}, [preferredCloudId]);

	return { domain, availableSites };
};
