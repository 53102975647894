/* eslint-disable @atlaskit/ui-styling-standard/enforce-style-prop */
import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { ExitingPersistence, SlideIn } from '@atlaskit/motion';

export type SidePanelProps = {
	/**
	Value used to account for the total height of the header so the side panel
  	appears below it
	*/
	headerHeight?: number | undefined;
	isFixed?: boolean;
	children: React.ReactNode;
	displayTopBorder?: boolean;
	panelTop?: number;
};

const sidePanelContentStyling = xcss({
	position: 'absolute',
	width: '100%',
	backgroundColor: 'elevation.surface',
	height: '100%',
	top: '0',
	right: '0',
	left: '0',
	bottom: '0',
	display: 'flex',
	flexDirection: 'column',
});

export const SidePanel = ({
	headerHeight = 0,
	isFixed,
	children,
	displayTopBorder,
	panelTop = 0,
}: SidePanelProps) => {
	return (
		<ExitingPersistence appear>
			<SlideIn enterFrom="right" fade="inout">
				{(props) => (
					<div
						{...props}
						// `props` has className, so use inline styling to avoid conflicts
						style={{
							backgroundColor: 'elevation.surface',
							position: isFixed ? 'fixed' : 'absolute',
							right: '0',
							top: `${panelTop}px`,
							width: '320px',
							padding: 'space.300',
							borderLeft: `1px solid ${token('color.border')}`,
							height: `calc(100vh - ${headerHeight}px)`,
							borderTop: displayTopBorder ? `1px solid ${token('color.border')}` : 'none',
						}}
					>
						<Box xcss={sidePanelContentStyling}>{children}</Box>
					</div>
				)}
			</SlideIn>
		</ExitingPersistence>
	);
};
