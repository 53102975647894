import { useCallback, useEffect, useRef, useState } from 'react';

import { type SuggestedIssue } from '../../common/types';
import { useAiIssueCreateAnalytics } from '../ai-issue-create-analytics';

import { fetchAiSuggestedFields } from './utils';

type UseFetchAiSuggestedFields = {
	cloudId: string;
	/**
	 * Confluence page in markdown plus format
	 */
	confluencePage: string;
	/**
	 * The snippet of text that is highlighted in markdown plus format
	 */
	highlightedText: string;
	onStateChange: (newState: FetchState) => void;
};

type AbortState = { state: 'abort'; fields: undefined };
type LoadingState = { state: 'loading'; fields: undefined };
type ErrorState = { state: 'error'; error: Error | unknown; fields: undefined };
type SuccessState = { state: 'idle'; fields: SuggestedIssue };
type FetchState = AbortState | LoadingState | ErrorState | SuccessState;

export const useFetchAiSuggestedFields = ({
	cloudId,
	confluencePage,
	highlightedText,
	onStateChange,
}: UseFetchAiSuggestedFields): FetchState & { trigger: () => Promise<void> } => {
	const { fireTrack } = useAiIssueCreateAnalytics();

	const [fetchState, setFetchState] = useState<FetchState>({ state: 'loading', fields: undefined });

	const abortControllerRef = useRef<AbortController | null>(null);

	const fetchAiFields = useCallback(async () => {
		if (!cloudId || !confluencePage) {
			return;
		}

		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}

		abortControllerRef.current = new AbortController();
		const currentAbortController = abortControllerRef.current;

		setFetchState({ state: 'loading', fields: undefined });

		try {
			fireTrack('aiIssueCreateFetchSuggestedFieldsRequest start');
			const suggestion = await fetchAiSuggestedFields({
				cloudId,
				confluencePage,
				highlightedText,
				signal: currentAbortController.signal,
			});
			fireTrack('aiIssueCreateFetchSuggestedFieldsRequest complete', {
				haveResult: !!suggestion,
			});

			if (currentAbortController.signal.aborted) {
				return;
			}

			if (suggestion) {
				setFetchState({ state: 'idle', fields: suggestion });
			} else {
				setFetchState({
					state: 'error',
					error: new Error('Error on suggesting AI Issue Create from Assistance Service'),
					fields: undefined,
				});
			}
		} catch (e: Error | unknown) {
			fireTrack('aiIssueCreateFetchSuggestedFieldsRequest fail', { message: (e as Error).message });
			if (currentAbortController.signal.aborted) {
				setFetchState({ state: 'abort', fields: undefined });
				return;
			}

			setFetchState({ state: 'error', error: e, fields: undefined });
		}
	}, [cloudId, confluencePage, fireTrack, highlightedText]);

	useEffect(() => {
		fetchAiFields();

		return () => {
			if (abortControllerRef.current) {
				abortControllerRef.current.abort();
			}
		};
	}, [fetchAiFields]);

	useEffect(() => {
		if (onStateChange) {
			onStateChange(fetchState);
		}
	}, [fetchState.state, fetchState.fields, onStateChange, fetchState]);

	const trigger = useCallback(() => {
		return fetchAiFields();
	}, [fetchAiFields]);

	return { ...fetchState, trigger };
};
