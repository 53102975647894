import React, { useCallback } from 'react';

import { Box, xcss } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	AIEventsInstrumentationProvider,
	useAIEventsInstrumentation,
} from '@atlassian/ai-analytics';

import { withFlags } from '@confluence/flags';
import { SidePanel } from '@confluence/side-panel';
import { useSidePanelStateActions } from '@confluence/side-panel-state-container';

import { IssueCreateSidePanelFooter } from './IssueCreateSidePanelFooter';
import { IssueCreateSidePanelBody } from './IssueCreateSidePanelBody';
import { IssueCreateSidePanelHeader } from './IssueCreateSidePanelHeader';
import type { IssueCreateSidePanelProps } from './__types__/IssueCreateSidePanel';
import { IssueCreateAiLoadingSidebar } from './IssueCreateAiLoadingSidebar';
import { useHasRouteChanged } from './useHasRouteChanged';
import { useIssueCreateSidePanelContext } from './IssueCreateSidePanelContextProvider';

// Currently a magic number, but will be replaced with a proper calculation
export const headerHeight = 56;

export const sidePanelTestId = 'issue-create-side-panel';
export const ISSUE_CREATE_SIDE_PANEL_ID = 'issue-create-side-panel';
export const ISSUE_CREATE_SIDE_PANEL_WIDTH = 320;

const sidePanelStyle = xcss({
	position: 'relative',
});

const IssueCreateSidePanelComponent = ({ onClose, flags }: IssueCreateSidePanelProps) => {
	const { showSidePanel, hideSidePanel } = useSidePanelStateActions();
	const routeHasChanged = useHasRouteChanged();

	if (routeHasChanged) {
		hideSidePanel();
		onClose();
	}
	const [state, actions] = useIssueCreateSidePanelContext();
	const { aiState, isCreatedWithAi } = state;
	const { trackAIInteractionDismiss } = useAIEventsInstrumentation();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const shouldShowAiFooter = isCreatedWithAi && aiState !== 'error';

	const isAiLoading = aiState === 'loading';
	const handleOnClose = useCallback(() => {
		if (isAiLoading) {
			trackAIInteractionDismiss();
		}
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'confluenceHighlightActionsMenu',
				action: 'closed',
				actionSubject: 'modalDialog',
				actionSubjectId: 'linkCreate',
				type: 'ui',
				attributes: {
					isManuallyCreated: !isCreatedWithAi,
					isCreatedWithAi,
					isAiLoading,
				},
			},
		}).fire();
		hideSidePanel();
		onClose();
		void actions.setAiState('idle');
	}, [
		hideSidePanel,
		onClose,
		isCreatedWithAi,
		isAiLoading,
		trackAIInteractionDismiss,
		createAnalyticsEvent,
		actions,
	]);

	showSidePanel();

	return (
		<Box testId={sidePanelTestId} xcss={sidePanelStyle}>
			<SidePanel isFixed headerHeight={headerHeight} panelTop={headerHeight}>
				<IssueCreateAiLoadingSidebar>
					<IssueCreateSidePanelHeader onClose={handleOnClose} />
					<IssueCreateSidePanelBody onClose={handleOnClose} flags={flags} />
					{shouldShowAiFooter && <IssueCreateSidePanelFooter />}
				</IssueCreateAiLoadingSidebar>
			</SidePanel>
		</Box>
	);
};

const IssueCreateSidePanelComponentWithProvider = ({
	onClose,
	flags,
}: IssueCreateSidePanelProps) => {
	return (
		<AIEventsInstrumentationProvider
			config={{
				// Required configruation
				product: 'confluence',
				subproduct: 'jira',
				aiFeatureName: 'confluenceAiWorkCreation',
				proactiveGeneratedAI: 0,
				userGeneratedAI: 1,
				// Optional configuration
				source: 'confluenceHighlightIssueCreate',
				skipAISessionValidation: true,
			}}
		>
			<IssueCreateSidePanelComponent onClose={onClose} flags={flags} />
		</AIEventsInstrumentationProvider>
	);
};

export const IssueCreateSidePanel = withFlags(IssueCreateSidePanelComponentWithProvider);
