/**
 * This file was cloned from https://bitbucket.org/atlassian/pf-adf-service/src/master/src/lib/converters/markdownPlus-to-adf.ts and slightly modified to work in the browser.
 */
import { type Schema } from 'prosemirror-model';

import { type DocNode } from '@atlaskit/adf-schema';
import { defaultSchema } from '@atlaskit/adf-schema/schema-default';
import { JSONTransformer } from '@atlaskit/editor-json-transformer';
import { backendConvertMarkdownToProsemirrorNode } from '@atlassian/ai-model-io/convert-markdown-to-prosemirror';
import type { IDMap } from '@atlassian/ai-model-io/src/convert-prosemirror-to-markdown/pm-markdown/serializer';
import { type FeatureToggles } from '@atlassian/ai-model-io/src/feature-keys';

const serializer = new JSONTransformer();
/**
 * This function converts markdown-plus to ADF (aka ProsemirrorNode).
 *
 * @param markdown the markdown-plus to convert to ADF
 * @param idMap [optional] used to rehydrate <custom> tags in markdown-plus
 * @param editorSchema [optional] provide this if you need a custom editor schema, otherwise we use the default for this API
 * @param featureToggles [optional] choose which features to enable / disable
 * @returns ADF (aka ProsemirrorNode)
 */
export function markdownPlusToAdf({
	markdown,
	idMap = {},
	editorSchema,
	featureToggles = { markdownPlus: true },
}: {
	markdown: string;
	/** Used to rehydrate <custom> tags in markdown-plus
	 * @default {}
	 */
	idMap?: IDMap;
	/**  Provide this if you need a custom editor schema, otherwise we use the default for this API */
	editorSchema?: Schema<any, any>;
	/**
	 * Choose which features to enable or disable
	 * @default Object { markdownPlus: true }
	 */
	featureToggles?: FeatureToggles;
}): DocNode {
	// Default to markdownPlus being enabled unless explicitly disabled
	if (featureToggles === undefined) {
		featureToggles = {};
	}
	if (featureToggles?.markdownPlus === undefined) {
		featureToggles.markdownPlus = true;
	}

	// Convert markdown-plus to ProseMirrorNode
	const proseMirrorNode = backendConvertMarkdownToProsemirrorNode({
		markdown,
		schema: editorSchema || defaultSchema,
		idMap,
		featureToggles,
	});

	// Convert ProseMirrorNode to ADF
	return serializer.encode(proseMirrorNode) as DocNode;
}
