import React, { type ReactNode, useCallback, useMemo, useRef } from 'react';

import uuid from 'uuid/v4';

import {
	type AIEventsInstrumentationConfig,
	AIEventsInstrumentationContext,
	type AIEventsInstrumentationContextType,
} from '../context';
import { AISessionState, EventDispatcherSources } from '../events';

type AIEventsInstrumentationProviderProps = {
	config: AIEventsInstrumentationConfig;
};

export const defaultConfigValues: Pick<
	AIEventsInstrumentationConfig,
	'actionSubjectId' | 'isAIFeature' | 'eventDispatcherSource' | 'skipAISessionValidation'
> = {
	isAIFeature: 1,
	actionSubjectId: 'client',
	eventDispatcherSource: EventDispatcherSources.JSLib,
	skipAISessionValidation: false,
};

const validateAIEventsConfig = (config: AIEventsInstrumentationConfig) => {
	if (!config.aiFeatureName.match(/^[a-z][A-Za-z]*$/)) {
		// eslint-disable-next-line no-console
		console.error('[@atlassian/ai-analytics]: please set aiFeatureName in camelCase');
	}
};

export const AIEventsInstrumentationProvider = ({
	children,
	config,
}: {
	children: ReactNode;
} & AIEventsInstrumentationProviderProps) => {
	const singleInstrumentationIDRef = useRef<string | undefined>(undefined);
	const aiSessionStateRef = useRef(AISessionState.unset);

	const setAISessionState = useCallback((nextState: AISessionState) => {
		aiSessionStateRef.current = nextState;
	}, []);

	const getAISessionState = useCallback(() => {
		return aiSessionStateRef.current;
	}, []);

	const refreshSingleInstrumentationID = useCallback(
		(customSingleInstrumentationID?: string) => {
			// priority for setting singleInstrumentationID override rule
			// 1. from consumer event attribute
			// 2. from consumer configuration
			// 3. default behaviour generates through uuid lib
			singleInstrumentationIDRef.current =
				customSingleInstrumentationID ?? config.singleInstrumentationID ?? uuid();
		},
		[config.singleInstrumentationID],
	);

	const getSingleInstrumentationID = useCallback(() => {
		// this handles a race condition when we have a new singleInstrumentationID from the user config
		// but Provider knows only about the initial singleInstrumentationID. In this case we need to update
		// singleInstrumentationID at first step and then return a new one.
		if (
			config.singleInstrumentationID &&
			config.singleInstrumentationID !== singleInstrumentationIDRef.current
		) {
			refreshSingleInstrumentationID();
		}
		return singleInstrumentationIDRef.current;
	}, [refreshSingleInstrumentationID, config.singleInstrumentationID]);

	const contextValue = useMemo<AIEventsInstrumentationContextType>(() => {
		validateAIEventsConfig(config);
		return {
			config: {
				...defaultConfigValues,
				...config,
				product: config.product.toLowerCase(),
			},
			refreshSingleInstrumentationID,
			getSingleInstrumentationID,
			setAISessionState,
			getAISessionState,
		};
	}, [
		config,
		refreshSingleInstrumentationID,
		getSingleInstrumentationID,
		setAISessionState,
		getAISessionState,
	]);

	return (
		<AIEventsInstrumentationContext.Provider value={contextValue}>
			{children}
		</AIEventsInstrumentationContext.Provider>
	);
};
